import Lottie from 'react-lottie';
import * as animationData from '../public/loading.json'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function Button({text, onClick, loading, big, classes, type, color}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const buttonType = type || "";

    return <button

        className={classes + " " + classNames(
            big
                ? 'w-full py-3 text-lg'
                : '',
            `inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${color?color:"bg-gradient-to-r from-orange-500 to-rescribe"}`
        )}
        onClick={onClick}
        type={buttonType}
    >
        {loading && <div className={"absolute"}>
            <Lottie options={defaultOptions}
                    height={20}
                    width={80}
            />
        </div>}
        <div className={`${loading ? "opacity-0" : "opacity-1"}`}>{text}</div>
    </button>
}
