import {useState} from "react";
import {
    createUserWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider
} from "firebase/auth";
import {firebaseAuth} from "../util/firebase";
import Image from "next/image";
import nextApi from "../util/apiConfig";
import Layout from "../components/Layout";
import UnprotectedDefaultLayout from "../components/UnprotectedDefaultLayout";
import Link from "next/link";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {useTranslation} from "next-i18next";
import {Button} from "../components/Button";
import globalmeta from "../globalmeta";
import DefaultHead from "../components/DefaultHead";
import * as animationData from "../public/loading.json";
import Lottie from "react-lottie";

function Signup(props) {
    const [email, setEmail] = useState("");
    const [passwordOne, setPasswordOne] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [agreePrivacy, setAgreePrivacy] = useState(false);
    const {t} = useTranslation('signup')
    const locale = props.locale;

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    async function createEmailUserInDB(user) {
        setLoading(true);
        nextApi.post("/users", {
            email: user.email,
            firstname: firstName,
            lastname: lastName,
            firebase_id: user.uid,
            preferredLanguage: locale === "de" ? "de" : "en-US",
            provider: "email"
        }).catch(e => {
            setLoading(false);
            console.log(e)
        });
    }

    async function createSocialUserInDB(user, provider) {
        setLoading(true);
        nextApi.post("/users", {
            email: user.email,
            firstname: user.displayName ? user.displayName.substring(0, user.displayName.indexOf(' ')): "Rescribe",
            lastname: user.displayName ? user.displayName.substring(user.displayName.indexOf(' ') + 1): "User",
            firebase_id: user.uid,
            preferredLanguage: locale,
            provider: provider
        }).catch(e => {
            setLoading(false);
            console.log(e)
        });
    }

    let handleMailSignUp = (event) => {
        event.preventDefault();
        //Form Validation
        if (!isValidEmail(email)) {
            setError("Please provide a valid E-Mail");
            return;
        }
        if (!firstName) {
            setError("Please provide a Firstname");
            return;
        }
        if (!lastName) {
            setError("Please provide a Lastname");
            return;
        }
        if (passwordOne != passwordTwo) {
            setError("Password is not matching");
            return;
        }
        //Everything is valid
        setLoading(true);
        createUserWithEmailAndPassword(firebaseAuth, email, passwordOne)
            .then((user) => {
                createEmailUserInDB(user.user)
                    .catch(errordb => {
                        console.log(errordb)
                    });
            })
            .catch((error) => {
                setError(error.code);
                setLoading(false);
            });
    }

    let handleGoogleSignUp = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(firebaseAuth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user
                createSocialUserInDB(user, "google").then(() => {
                }).catch(e => {
                    console.log("Error creating user in DB from Google ", e)
                })
                // ...
            }).catch((error) => {
            console.log("Error creating Auth account from Google, ", error)
        });
    }

    let handleAppleSignUp = () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('name');
        if (locale === "de") {
            provider.setCustomParameters({
                locale: 'de'
            });
        }
        signInWithPopup(firebaseAuth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user
                createSocialUserInDB(user, "Apple").then(() => {
                }).catch(e => {
                    console.log("Error creating user in DB from Apple ", e)
                })
            }).catch((error) => {
            console.log("Error creating Auth account from Apple, ", error)
        });
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            <div className="flex min-h-screen">
                <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative">
                    {loading && <div
                        className="absolute top-0 left-0 flex min-h-screen h-full w-screen md:w-full  bg-gray-200 bg-opacity-70 z-20 flex justify-center items-center">
                        <Lottie options={defaultOptions}
                                height={50}
                                width={180}
                        />
                    </div>}
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Image src="/favicon.png" width={50} height={50}/>
                            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">{t('heading')}</h2>
                            {locale !== "de" ? (<p className="mt-2 text-sm text-gray-600">
                                Or{' '}
                                <Link href="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    login to your existing account
                                </Link>
                            </p>) : (<p className="mt-2 text-sm text-gray-600">
                                Oder{' '}
                                <Link href="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    logge dich mit einem bestehenden Account ein
                                </Link>
                            </p>)}
                        </div>

                        <div className="mt-8">
                            <div>
                                <div>
                                    <p className="text-sm font-medium text-gray-700">{t('google-signin-heading')}</p>
                                    <div className="mt-1 grid grid-cols-3 gap-3">
                                        <div>
                                            <button
                                                onClick={() => {
                                                    handleGoogleSignUp()
                                                }}
                                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                                            >
                                                <Image src="/google.svg" width={20} height={20}/>
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    handleAppleSignUp()
                                                }}
                                                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                                            >
                                                <Image src="/apple.svg" width={16} height={20}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="relative mt-6">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300"/>
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="bg-white px-2 text-gray-500">{t('divider-text')}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-6">
                                <form action="#" method="POST" className="space-y-6" onSubmit={handleMailSignUp}>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            {t('field-firstname')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                required
                                                onChange={e => setFirstName(e.target.value)}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-3/8">
                                        <label className="block text-sm font-medium text-gray-700">
                                            {t('field-lastname')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                required
                                                onChange={e => setLastName(e.target.value)}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            {t('field-email')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                required
                                                onChange={e => setEmail(e.target.value)}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="space-y-1">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            {t('field-passwordone')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                onChange={e => setPasswordOne(e.target.value)}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                            {t('field-passwordtwo')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                onChange={e => setPasswordTwo(e.target.value)}
                                                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-1 flex flex-row items-center">
                                        <div className="mt-1">
                                            <input
                                                id="checkboxagb"
                                                name="checkboxagb"
                                                type="checkbox"
                                                required
                                                onChange={e => setAgreeTerms(e.target.value)}
                                                className="block h-3 w-3 appearance-none rounded-md border border-gray-300 p-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        {locale === "de" ? <label htmlFor="checkboxagb"
                                                                  className="block text-sm font-medium text-gray-700 ml-2">
                                            Hiermit akzeptiere ich die <Link
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            href={"https://rescribe.ai/agb"}>AGBs*</Link>
                                        </label> : <label htmlFor="checkboxagb"
                                                          className="block text-sm font-medium text-gray-700 ml-2">
                                            I have read and accept the <Link
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            href={"https://rescribe.ai/agb"}>terms and conditions*</Link>
                                        </label>}

                                    </div>
                                    <div className="space-y-1 flex flex-row items-center">
                                        <div className="mt-1">
                                            <input
                                                id="checkboxagb"
                                                name="checkboxagb"
                                                type="checkbox"
                                                required
                                                onChange={e => setAgreePrivacy(e.target.value)}
                                                className="block h-3 w-3 appearance-none rounded-md border border-gray-300 p-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        {locale === "de" ? <label htmlFor="checkboxprivacy"
                                                                  className="block text-sm font-medium text-gray-700 ml-2">
                                            Hiermit akzeptiere ich die <Link
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            href={"https://rescribe.ai/agb"}>Datenschutzerklärung*</Link>
                                        </label> : <label htmlFor="checkboxprivacy"
                                                          className="block text-sm font-medium text-gray-700 ml-2">
                                            I have read and accept the <Link
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            href={"https://rescribe.ai/agb"}>privacy policy*</Link>
                                        </label>}

                                    </div>
                                    {error && <div>
                                        <p className="text-red">{error}</p>
                                    </div>}

                                    <div>
                                        <Button text={t('button-signup-text')} loading={loading} classes={"px-12 pt-2 pb-2 w-full"} type={"submit"}>

                                        </Button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}

export default Signup;

export async function getStaticProps({locale}) {
    return {
        props: {
            locale: locale,
            ...(await serverSideTranslations(locale, ['signup']))
        },
    };
}

Signup.getLayout = function getLayout(page) {
    let {locale} =page.props;
    const structuredLd = JSON.stringify({
        "@context": globalmeta.siteUrl + "/" + locale + "/signup",
        "description": locale === "de" ? "ReScribe - Erstelle deinen Account" : "ReScribe - Create your account"
    });

    return (
        <Layout>
            <DefaultHead canonicalUrl={globalmeta.siteUrl + "/" + locale + "/signup"}
                         title={locale === "de" ? "ReScribe - Erstelle deinen Account" : "ReScribe - Create your account"}
                         description={locale === "de" ? "ReScribe - Erstelle deinen Account" : "ReScribe - Create your account"}
                         locale={locale} structuredData={structuredLd}/>
            <UnprotectedDefaultLayout>{page}</UnprotectedDefaultLayout>
        </Layout>
    );
};
